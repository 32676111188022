<template>
  <v-app-bar flat height="90" min-width="300">
    <v-container class="py-0 px-0 pa-sm-1 fill-height">
      <router-link to="/" class="d-none d-sm-flex align-center text-decoration-none">
        <img :src="require('@/assets/images/sslogosmall.png')" height="56" />
      </router-link>
      <router-link to="/" class="d-sm-none align-center text-decoration-none">
        <img :src="require('@/assets/images/sslogo2.png')" height="56" />
      </router-link>

      <v-spacer px-0></v-spacer>

      <div v-if="!isLoggedIn">
        <v-btn text class="ma-0" to="/auth/signup">
          Sign Up
        </v-btn>
        <v-btn outlined class="ma-0" to="/auth/signin">
          Sign In
        </v-btn>
      </div>
      <v-menu v-if="!!isLoggedIn" offset-y left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn
            class="ma-2"
            outlined
            large
            v-on="on"
          ><v-icon>mdi-account-outline</v-icon>{{ userName }}
          </v-btn>
        </template>

        <!-- user menu list -->
        <v-list nav>

          <v-list-item to="/home">
            <v-list-item-icon>
              <v-icon small>mdi-monitor-dashboard </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-1"></v-divider>

          <v-list-item to="/auth/signout">
            <v-list-item-icon>
              <v-icon small>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

    </v-container>
  </v-app-bar>
</template>

<script>
import config from '../../configs'

import { mapState } from 'vuex'

export default {
  data() {
    return {
      menu: config.toolbar.user
    }
  },
  computed:  mapState({
    isLoggedIn: (state) => state.auth.status.loggedIn,
    userName: (state) => state.auth.status.userName
  })
}
</script>
