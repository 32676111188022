<template>
  <div>
    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >

        <div class="d-flex flex-grow-1 align-center">

          <router-link style="text-decoration: none; color: inherit;" to="/home">
            <div class="title font-weight-bold text-uppercase primary--text">{{ product.name }}</div>
          </router-link>

          <v-spacer></v-spacer>

          <toolbar-user />
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height" :fluid="!isContentBoxed">
        <slot></slot>
      </v-container>
      <v-footer app inset>
        <v-spacer></v-spacer>
        <div class="overline">
          Built with
          <v-icon small color="pink">mdi-heart</v-icon>
        </div>
      </v-footer>
    </v-main>

  </div>
</template>

<script>
import { mapState } from 'vuex'

// navigation menu configurations
import config from '../configs'

import ToolbarUser from '../components/toolbar/ToolbarUser'

export default {
  components: {
    ToolbarUser
  },
  data () {
    return {
      drawer: null,
      showSearch: false,

      navigation: config.navigation
    }
  },
  computed: {
    ...mapState('app', ['product', 'isContentBoxed', 'menuTheme', 'toolbarTheme', 'isToolbarDetached'])
  },
  methods: {
    onKeyup (e) {
      this.$refs.search.focus()
    }
  }
}
</script>

<style scoped>

</style>
