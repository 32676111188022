//before continuing fetch server data

import store from '../store'
import authHeader from '@/store/auth/auth-header'

export async function initGuard(to, from, next) {
  //If already synced, return
  if (store.getters['auth/synced'])  return next()

  //If no auth header exists, return
  if (!('Authorization' in authHeader()) ) return next()

  console.log('Token is stored, lets get data from server')

  await store.dispatch('auth/init')

  return next()
}
