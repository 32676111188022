//GUARDS

import store from '../store'

/**
 * Before each route update
 */
export function authGuard(to, from, next) {

  const isLoggedIn = store.getters['auth/isLoggedIn']
  const isPending = store.getters['auth/isPending']
  const isVerified = store.getters['auth/isVerified']

  // if logged in then divert to provided named route
  if (to.matched.some((record) => !!record.meta.ifLoggedIn)) {
    if (isLoggedIn) return next({ name: to.meta.ifLoggedIn })
  }
  // if pending in then divert to provided named route
  if (to.matched.some((record) => !!record.meta.ifPending)) {
    if (isPending) return next({ name: to.meta.ifPending })
  }
  // if verified in then divert to provided named route
  if (to.matched.some((record) => !!record.meta.ifVerified)) {
    if (isVerified) return next({ name: to.meta.ifVerified })
  }
  // Must be logged in and verified - auth
  if (to.matched.some((record) => record.meta.auth)) {
    if (!isLoggedIn) return next({ name: 'auth-signin' })
    if (isPending) return next({ name: 'auth-verify-email' })
  }
  // Must be logged in and verified - auth
  if (to.matched.some((record) => !!record.meta.pending)) {
    if (!isPending) return next({ name: 'home' })
  }
  if (to.matched.some((record) => !!record.meta.loggedIn)) {
    if (!isLoggedIn) return next({ name: 'home' })
  }
  if (to.matched.some((record) => !!record.meta.guest)) {
    if (isLoggedIn) return next({ name: 'home' })
  }
  console.log('loggedin - ', isLoggedIn)
  console.log('pending - ', isPending)
  console.log('verified - ', isVerified)
  console.log('name - ', store.getters['auth/userName'])

  return next() // No guard has been needed, proceed

}

//
//
// const isLoggedIn = store.getters['auth/isLoggedIn']
// const isPending = store.getters['auth/isPending']
// const isVerified = store.getters['auth/isVerified']
//
// // if logged in then divert to provided named route
// if (to.matched.some((record) => !!record.meta.ifLoggedIn)) {
//   if (isLoggedIn) return next({ name: to.meta.ifLoggedIn })
// }
// // if pending in then divert to provided named route
// if (to.matched.some((record) => !!record.meta.ifPending)) {
//   if (isPending) return next({ name: to.meta.ifPending })
// }
// // if verified in then divert to provided named route
// if (to.matched.some((record) => !!record.meta.ifVerified)) {
//   if (isVerified) return next({ name: to.meta.ifVerified })
// }
// // Must be logged in and verified - auth
// if (to.matched.some((record) => record.meta.auth)) {
//   if (!isLoggedIn) return next({ name: 'auth-signin' })
//   if (isPending) return next({ name: 'auth-verify-email' })
// }
// // Must be logged in and verified - auth
// if (to.matched.some((record) => !!record.meta.pending)) {
//   if (!isPending) return next({ name: 'home' })
// }
// if (to.matched.some((record) => !!record.meta.loggedIn)) {
//   if (!isLoggedIn) return next({ name: 'home' })
// }
// console.log('loggedin - ', isLoggedIn)
// console.log('pending - ', isPending)
// console.log('verified - ', isVerified)
// console.log('name - ', store.getters['auth/userName'])
//
// return next() // No guard has been needed, proceed
