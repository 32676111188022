<template>
  <div>
    <toolbar-main />
    <v-main>
      <router-view :key="$route.fullPath"></router-view>

      <v-footer color="transparent">
        <v-container class="py-5">
          <v-row>
            <v-col cols="12" md="4">
              <div class="text-h6 text-lg-h5 font-weight-bold">Navigation</div>
              <div style="width: 80px; height: 2px" class="mb-5 mt-1 primary" />
              <div class="d-flex flex-wrap ">
                <div v-for="(link, i) in links" :key="i" class="w-half body-1 mb-1">
                  <router-link
                    v-if="link.to"
                    class="text-decoration-none text--primary"
                    :to="link.to"
                  >{{ link.label }}
                  </router-link>
                  <a
                    v-else
                    class="text-decoration-none text--primary"
                    :href="link.href"
                    :target="link.target || 'blank'"
                  >{{ link.label }}</a>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="text-h6 text-lg-h5 font-weight-bold">Contact Information</div>
              <div style="width: 80px; height: 2px" class="mb-5 mt-1 primary" />
              <div class="d-flex mb-2 font-weight-bold">
                <v-icon color="primary lighten-1" class="mr-2">mdi-map-marker-outline</v-icon>
                Some building, In Some Road, Somewhere, UK
              </div>
              <div class="d-flex mb-2">
                <v-icon color="primary lighten-1" class="mr-2">mdi-phone-outline</v-icon>
                <a href="#" class="text-decoration-none text--primary">01234 567 890</a>
              </div>
              <div class="d-flex mb-2">
                <router-link
                  class="text-decoration-none primary--text font-weight-bold"
                  to="/contactus"
                >
                  <v-icon color="primary lighten-1" class="mr-2">mdi-email-outline</v-icon>
                  Contact Us
                </router-link>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="text-h6 text-lg-h5 font-weight-bold">Newsletter</div>
              <div style="width: 80px; height: 2px" class="mb-5 mt-1 primary" />
              <v-form ref="form" class="d-flex flex-column flex-lg-row w-full" lazy-validation @submit.prevent="">
                <v-text-field
                  v-model="email"
                  :rules="rules.email"
                  :disabled="requestSent"
                  name="email"
                  background-color="surface"
                  outlined
                  solo
                  label="Your email"
                  dense
                  height="44"
                  class="mr-lg-2"
                  @input="resetErrors"
                  @keyup.enter.prevent
                  @keydown.enter="submit"
                ></v-text-field>
                <v-btn
                  ref="subscribe"
                  :loading="isLoading"
                  :disabled="requestSent"
                  large
                  color="primary"
                  @click="submit"
                >{{ buttonLabel }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <div class="text-center caption">
            © 2021. All Rights Reserved
          </div>
        </v-container>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import config from '@/configs'
import ToolbarMain from '../components/toolbar/ToolbarMain'

import { mapState } from 'vuex'

export default {
  components: {
    ToolbarMain
  },
  data () {
    return {
      buttonLabel: 'Subscribe',
      isLoading: false,
      requestSent: false,
      serverMessages: {},

      email: '',
      config,
      links: [{
        label: 'Sign In',
        to: '/auth/signin'
      }, {
        label: 'Sign Up',
        to: '/auth/signup'
      }, {
        label: 'Forgotton Password',
        to: '/auth/forgot-password'
      }],
      rules: {
        email: [
          (v) => (v && Boolean(v)) || 'Email is Required',
          (v) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Please provide a valid email',
          () => !(this.otherError) || 'Sorry, Unable to Subscribe'
        ]
      }
    }
  },

  methods: {
    submit () {
      this.buttonLabel = 'Thank You'
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.sendNewsletterRequest(this.email)
      }
    },
    sendNewsletterRequest (email) {
      this.$store.dispatch('landing/newsletter', email).then(
        () => {
          this.isLoading = false
          this.requestSent = true
        },
        (error) => {
          this.isLoading = false
          this.requestSent = false
          this.otherError = true
          this.$refs.form.validate()
        }
      )
    },
    resetErrors () {
      this.otherError = false
    }
  }
}
</script>
<style>
</style>
