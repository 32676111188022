import icons from './icons'
import theme from './theme'
import toolbar from './toolbar'
import navigation from './navigation'

export default {
  // product display information
  product: {
    name: 'Sureskill',
    version: '1.0.0'
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // navigation configs
  navigation
}
