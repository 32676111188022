import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.sureskill.com/api/auth/' : 'http://api2.test/api/auth/'

// if (process.env.NODE_ENV === 'production') {
//   const API_URL = 'https://api1.tybx.io/api/auth/'
// } else {
//   const API_URL = 'http://api2.test/api/auth/'
// }
//

class AuthService {
// token storage - currently localstorge
  storageStore (token) {
    localStorage.setItem('access_token', JSON.stringify(token))
  }

  storageRemove () {
    localStorage.removeItem('access_token')
  }

  login (user) {
    this.storageRemove()

    return axios.post(API_URL + 'login', {
      email: user.email,
      password: user.password
    })
      .then((response) => {
        if (response.data['access_token']) {
          this.storageStore(response.data['access_token'])
        }

        return response.data
      })
  }

  register (user) {
    this.storageRemove()

    return axios
      .post(API_URL + 'register', {
        name: user.name,
        email: user.email,
        password: user.password
      })
      .then((response) => {
        if (response.data['access_token']) {
          this.storageStore(response.data['access_token'])
        }

        return response.data
      })
  }

  sendReset (email) {
    return axios
      .post(API_URL + 'forgot-password', {
        email: email
      })
      .then((response) => {
        return response.data
      })
  }

  passwordReset (reset) {
    return axios
      .post(API_URL + 'reset-password', {
        email: reset.email,
        password: reset.newPassword,
        password_confirmation: reset.newPassword,
        token: reset.token
      })
      .then((response) => {
        if (response.data['access_token']) {
          this.storageStore(response.data['access_token'])
        }

        return response.data
      })
  }

  logout () {
    return axios.post(API_URL + 'logout', {}, { headers: authHeader() })
      .then((response) => {
        this.storageRemove()

        return response.data
      })
  }

  resend () {
    return axios.post(API_URL + 'resend', {}, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

  refresh () {
    this.storageRemove()

    return axios.post(API_URL + 'refresh', {}, { headers: authHeader() })
      .then((response) => {
        if (response.data['access_token']) {
          this.storageStore(response.data['access_token'])
        }

        return response.data
      })
  }

  getMe () {
    if (!authHeader()) {
      const error = { message: 'No token stored' }

      console.log('No Token Stored')

      return Promise.reject(error)
    }

    return axios.post(API_URL + 'me', {}, { headers: authHeader() })
      .then((response) => {
        console.log('RESPONSE-', response)

        return response.data
      })
      .catch((error) => {
        console.log('bahh')

        return Promise.reject(error)
      })

  }

  async init () {

    return await axios.post(API_URL + 'me', {}, { headers: authHeader() })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        this.storageRemove()
        console.log('rmoved')

        return Promise.reject(error)
      })

  }

  provider (provider) {
    localStorage.removeItem('user')

    // window.location.href = WEB_URL + 'login/' + provider.id

    return axios
      // .post(API_URL + 'login/' + provider.id)
      .post(API_URL + 'login/' + provider.id)
      .then((response) => {

        console.log('provider login')
        console.log(response.data)
        if (response.data['access_token']) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }
}

export default new AuthService()
