import Vue from 'vue'
import Router from 'vue-router'

// Routes
import PagesRoutes from './pages.routes'
import AuthRoutes from './auth.routes'
import LandingRoutes from './landing.routes'

//Guards
import { authGuard } from '@/router/auth.guard'
import { initGuard } from '@/router/init.guard'

const API_URL = 'http://api2.test/api/auth/'

Vue.use(Router)

export const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "blank" */ '@/pages/auth/AwaitingApproval.vue'),
    meta: {
      layout: 'landing',
      auth: true
    }
  }, {
    path: '/contactus',
    name: 'contact',
    component: () => import(/* webpackChunkName: "blank" */ '@/pages/landing/Contact.vue'),
    meta: {
      layout: 'auth'
    }
  },{
    path: '/blank',
    name: 'blank',
    component: () => import(/* webpackChunkName: "blank" */ '@/pages/BlankPage.vue'),
    meta: {
      auth: true
    }
  },
  ...PagesRoutes,
  ...AuthRoutes,
  ...LandingRoutes,
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  }]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {

  return initGuard(from, to, next)
})

router.beforeEach((to, from, next) => {

  return authGuard(to, from, next)

})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
