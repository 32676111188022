export default [{
  path: '/auth/signin',
  name: 'auth-signin',
  component: () => import(/* webpackChunkName: "auth-signin" */ '@/pages/auth/SigninPage.vue'),
  meta: {
    layout: 'auth',
    ifLoggedIn: 'home',
    ifPending: 'auth-verify-email'
  }
}, {
  path: '/auth/signup',
  name: 'auth-signup',
  component: () => import(/* webpackChunkName: "auth-signup" */ '@/pages/auth/SignupPage.vue'),
  meta: {
    layout: 'auth',
    ifLoggedIn: 'home',
    ifPending: 'auth-verify-email'
  }
}, {
  path: '/auth/signout',
  name: 'auth-signout',
  component: () => import(/* webpackChunkName: "auth-signup" */ '@/pages/auth/SignoutPage.vue'),
  meta: {
    layout: 'auth',
    loggedIn: true
  }
}, {
  path: '/auth/verify-email',
  name: 'auth-verify-email',
  component: () => import(/* webpackChunkName: "auth-verify-email" */ '@/pages/auth/VerifyEmailPage.vue'),
  meta: {
    layout: 'auth',
    pending: true
  }
}, {
  path: '/auth/forgot-password',
  name: 'auth-forgot',
  component: () => import(/* webpackChunkName: "auth-forgot" */ '@/pages/auth/ForgotPage.vue'),
  meta: {
    layout: 'auth',
    guest: true
  }
}, {
  path: '/auth/reset-password/:token',
  name: 'auth-reset',
  component: () => import(/* webpackChunkName: "auth-reset" */ '@/pages/auth/ResetPage.vue'),
  meta: {
    layout: 'auth'
  }
}]
