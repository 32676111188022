import LandingService from './landing.service'

const state = {
  status: {
    messageSent: false
  }
}

const actions = {
  sendMessage ({ commit }, message) {
    return LandingService.sendMessage(message).then(
      () => {
        commit('messageSent')

        return Promise.resolve()
      },
      (error) => {
        commit('messageFailure')

        return Promise.reject(error)
      }
    )
  },
  newsletter ({ commit }, email) {
    return LandingService.newsletter(email).then(
      () => {
        commit('messageSent')

        return Promise.resolve()
      },
      (error) => {
        commit('messageFailure')

        return Promise.reject(error)
      }
    )
  }
}

const mutations = {
  messageSent (state) {
    state.status.messageSent = true
  },
  messageFailure (state) {
    state.status.messageSent = false
  }
}

const getters = {
  messageSent () {
    return state.status.messageSent
  }
}

// export default class AuthModule {
//
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
//
