import AuthService from './auth.service'

console.log('Creating Store ', process.env.NODE_ENV)
const state = {
  status: {
    synced: false,
    loggedIn: false,
    verified: false,
    userName: '',
    notifCount: '0'
  }
}

const actions = {
  login ({ commit }, user) {
    return AuthService.login(user).then(
      (me) => {
        commit('meReceived', me)

        return Promise.resolve(me)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  logout ({ commit }) {
    return AuthService.logout().then(
      () => {
        commit('meFailure')

        return Promise.resolve(true)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  register ({ commit }, user) {
    return AuthService.register(user).then(
      (me) => {
        commit('meReceived', me)

        return Promise.resolve(me)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  verificationStatus ({ commit }) {
    return AuthService.verificationStatus().then(
      (status) => {
        commit('meReceived', status)

        return Promise.resolve(status)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  getMe ({ commit }) {

    return AuthService.getMe().then(
      (me) => {
        console.log('yay')
        commit('meReceived', me)

        return Promise.resolve(me)
      },
      (error) => {
        console.log('nay')
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  async init ({ commit }) {

    return await AuthService.init()
      .then(
        (me) => {
          console.log('yay2 - init', me)
          commit('meReceived', me)

          return Promise.resolve(me)
        },
        (error) => {
          console.log('nay - init')
          commit('meFailure')

          return Promise.reject(error)
        }
      )
  },
  init2 ({ dispatch }) {
    return Promise.all([
      dispatch('getMe').then(
        (me) => {
          console.log('yay2')
          commit('meReceived', me)

          return Promise.resolve(me)
        },
        (error) => {
          console.log('nay2')
          commit('meFailure')

          return Promise.reject(error)
        })
    ]
    )
  },
  resend ({ commit }) {
    return AuthService.resend().then(
      (me) => {
        commit('meReceived', me)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  sendReset ({ commit }, email) {
    return AuthService.sendReset(email).then(
      () => {
        commit('meFailure')

        return Promise.resolve(true)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  passwordReset ({ commit }, reset) {
    return AuthService.passwordReset(reset).then(
      () => {
        commit('meFailure')

        return Promise.resolve(true)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  provider ({ commit }, provider) {
    return AuthService.provider(provider).then(
      (response) => {
        commit('registerSuccess')

        return Promise.resolve(response.data)
      },
      (error) => {
        commit('registerFailure')

        return Promise.reject(error)
      }
    )
  }
}

const mutations = {
  meReceived (state, me) {
    state.status.synced = true
    state.status.loggedIn = true
    state.status.verified = me['verified']
    state.status.userName = me['name']
    state.status.notifCount = me['notifCount']
  },
  meFailure (state) {
    state.status.synced = true
    state.status.loggedIn = false
    state.status.verified = false
    state.status.userName = ''
    state.status.notifCount = '0'
  },
  loginSuccess (state, user) {
    state.status.loggedIn = true
    state.user = user
  },
  loginFailure (state) {
    state.status.loggedIn = false
    state.user = null
  },
  logout (state) {
    state.status.loggedIn = false
    state.status.pending = false
    state.user = null
  }
  ,
  registerSuccess (state) {
    state.status.loggedIn = true
  }
  ,
  registerFailure (state) {
    state.status.loggedIn = false
  },
  statusReceived (state, status) {
    console.log('muteate status', status)
    state.user.verified = status['verified']
  }
}

const getters = {
  synced () {
    return state.status.synced
  },
  isLoggedIn () {
    return state.status.loggedIn
  },
  isPending () {
    return state.status.loggedIn && (!state.status.verified)
  },
  isVerified () {
    return state.status.verified
  },
  userName () {
    return state.status.userName
  }
}

// export default class AuthModule {
//
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
//
