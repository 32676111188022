import axios from 'axios'

const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.sureskill.com/api/' : 'http://api2.test/api/'

// if (process.env.NODE_ENV === 'production') {
//   const API_URL = 'https://api1.tybx.io/api/auth/'
// } else {
//   const API_URL = 'http://api2.test/api/auth/'
// }
//

class LandingService {

  sendMessage (message) {

    return axios.post(API_URL + 'new-message', {
      name: message.name,
      email: message.email,
      message: message.message
    })
      .then((response) => {

        return response.data
      })
  }
  newsletter (email) {

    return axios.post(API_URL + 'newsletter', {
      email: email
    })
      .then((response) => {

        return response.data
      })
  }
}

export default new LandingService()
