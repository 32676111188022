<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn
        class="ma-2"
        outlined
        large
        v-on="on"
      ><v-icon>mdi-account-outline</v-icon>{{ userName }}
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>

      <v-divider class="my-1"></v-divider>

      <div v-if="!isLoggedIn">
        <v-list-item to="/auth/signin">
          <v-list-item-icon>
            <v-icon small>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.login') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/auth/signup">
          <v-list-item-icon>
            <v-icon small>mdi-account-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.register') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-if="isLoggedIn">
        <v-list-item to="/auth/signout">
          <v-list-item-icon>
            <v-icon small>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/blank">
          <v-list-item-icon>
            <v-icon small>mdi-checkbox-blank-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Blank</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import config from '../../configs'

import { mapState } from 'vuex'
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/

export default {
  data() {
    return {
      menu: config.toolbar.user
    }
  },
  computed:  mapState({
    isLoggedIn: (state) => state.auth.status.loggedIn,
    userName: (state) => state.auth.status.userName
  })
}
</script>
